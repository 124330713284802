import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routeOptions = [
  { path: "*", name: "404" },
  { path: "/", name: "Home", redirect: "/login" },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/tournament",
    name: "Tournament",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/category",
    name: "Category",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/champions-battle-games",
    name: "ChampionBattleGame",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-game-store",
    name: "NewGameUpload",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/gratification",
    name: "Gratification",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/game-update",
    name: "GameUpdate",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/revenue-report",
    name: "RevenueReport",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/block-users",
    name: "BlockUser",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/user-history",
    name: "UserHistory",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/campaign-history",
    name: "CampaignHistory",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/promotion",
    name: "Promotions",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-promotion-store",
    name: "NewPromotionUpload",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/promotion-update",
    name: "PromotionUpdate",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/features",
    name: "Features",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-feature-store",
    name: "NewFeatureUpload",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/feature-update",
    name: "FeatureUpdate",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/flows",
    name: "Flows",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-flow-store",
    name: "NewFlowUpload",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/flow-update",
    name: "FlowUpdate",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/analytics",
    name: "Analytics",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/report",
    name: "Report",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/feedback",
    name: "Feedback",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/schedule-time",
    name: "ScheduleTime",
    layout: "dashboard",
    meta: { requiresAuth: true },
  },

  { path: "/login", name: "Login" },
];

let routes = routeOptions.map((route) => {
  return {
    ...route,
    component: () =>
      import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`),
  };
});

// Adding layout property from each route to the metaObject, so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  const auth = JSON.parse(window.localStorage.getItem("auth"));

  if (to.name && to.name === "Login" && auth && auth.token) {
    return next("/dashboard");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth && auth.token) {
      return next();
    }
    return next("/login");
  }
  next();
});

export default router;
