<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="images/logo.png" alt="" /> <span>Deen</span>
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline">
      <a-menu-item>
        <router-link to="/dashboard">
          <span class="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 13H7C7.55228 13 8 13.4477 8 14V21C8 21.5523 7.55228 22 7 22H3C2.44772 22 2 21.5523 2 21V14C2 13.4477 2.44772 13 3 13ZM10 8H14C14.5523 8 15 8.44772 15 9V21C15 21.5523 14.5523 22 14 22H10C9.44772 22 9 21.5523 9 21V9C9 8.44772 9.44772 8 10 8ZM17 3H21C21.5523 3 22 3.44772 22 4V21C22 21.5523 21.5523 22 21 22H17C16.4477 22 16 21.5523 16 21V4C16 3.44772 16.4477 3 17 3Z"
                fill="#4B5563"
              />
            </svg>
          </span>
          <span class="label">Dashboard</span>
        </router-link>
      </a-menu-item>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['push-notifications']">
          <a-sub-menu key="push-notifications" title="Push Notifications">
            <a-menu-item key="promotion">
              <router-link to="/promotion">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 4C5 3.44772 5.44772 3 6 3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H6C5.44772 5 5 4.55228 5 4ZM7 6C7 5.44772 7.44772 5 8 5H12C12.5523 5 13 5.44772 13 6V12C13 13.1046 12.1046 14 11 14H9C7.89543 14 7 13.1046 7 12V6ZM10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Notification</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="features">
              <router-link to="/features">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 3C3.89543 3 3 3.89543 3 5V9C3 10.1046 3.89543 11 5 11H9C10.1046 11 11 10.1046 11 9V5C11 3.89543 10.1046 3 9 3H5ZM5 13C3.89543 13 3 13.8954 3 15V19C3 20.1046 3.89543 21 5 21H9C10.1046 21 11 20.1046 11 19V15C11 13.8954 10.1046 13 9 13H5ZM13 5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5ZM15 13C13.8954 13 13 13.8954 13 15V19C13 20.1046 13.8954 21 15 21H19C20.1046 21 21 20.1046 21 19V15C21 13.8954 20.1046 13 19 13H15Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <span class="label">Features</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="flows">
              <router-link to="/flows">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Flows</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Reports']">
          <a-sub-menu key="Reports" title="Reports">
            <a-menu-item>
              <router-link to="/analytics">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 4C4 3.44772 4.44772 3 5 3H9C9.55228 3 10 3.44772 10 4V20C10 20.5523 9.55228 21 9 21H5C4.44772 21 4 20.5523 4 20V4ZM6 5V19H8V5H6ZM12 9C12 8.44772 12.4477 8 13 8H17C17.5523 8 18 8.44772 18 9V20C18 20.5523 17.5523 21 17 21H13C12.4477 21 12 20.5523 12 20V9ZM14 10V19H16V10H14ZM20 13C20 12.4477 20.4477 12 21 12H23C23.5523 12 24 12.4477 24 13V20C24 20.5523 23.5523 21 23 21H21C20.4477 21 20 20.5523 20 20V13ZM22 14V19H22.9V14H22Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <span class="label">Analytics</span>
              </router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/report">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 2C1.89543 2 1 2.89543 1 4V20C1 21.1046 1.89543 22 3 22H21C22.1046 22 23 21.1046 23 20V4C23 2.89543 22.1046 2 21 2H3ZM3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H8ZM8 11C7.44772 11 7 10.5523 7 10C7 9.44772 7.44772 9 8 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H8ZM8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3H16C16.5523 3 17 3.44772 17 4C17 4.55228 16.5523 5 16 5H8Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <span class="label">Event Wise User</span>
              </router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/feedback">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <span class="label">Feedback List</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['logs']">
          <a-sub-menu key="logs" title="logs">
            <a-menu-item>
              <a href="https://developer.mydeen.app/log-viewer" target="_blank">
                <span class="icon">
                  <!-- You can customize the icon for log-viewer here -->
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6C4.34315 3 3 4.34315 3 6ZM5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L16.7071 8.70711Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <span class="label">Log Viewer</span>
              </a>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-menu-item>
        <div @click="logout" style="margin-left: 16px; margin-top: 5px">
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-log-out"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
          </span>
          <span class="label">Logout</span>
        </div>
      </a-menu-item>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import { sendRequest } from "@/http/axios.method";

export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      // sidebarCollapsedModel: this.sidebarCollapsed,
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
    };
  },
  methods: {
    logout() {
      sendRequest("post", "logout", {}, this.config)
        .then((response) => {
          if (response.success) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.icon {
  display: inline-block;
  margin-right: 10px;
}
.label {
  vertical-align: middle;
}
</style>
